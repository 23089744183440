<template>
  <div class="order-wrapper">
    <div class="order-title">{{ $t('order_manager.title') }}</div>
    <div
      class="content-wrapper"
      v-loading="isLoading"
      :element-loading-text="$t('loading')"
      element-loading-background="var(--report-analyse-mask)"
    >
      <div class="list-wrap">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="order_id" :label="$t('order_manager.index')" width="230" />
          <el-table-column prop="create_time" :label="$t('order_manager.create_time')" width="220" />
          <el-table-column prop="product_name" :label="$t('order_manager.service_content')" />
          <el-table-column prop="order_type" :label="$t('order_manager.type')" :width="$isZh() ? '100' : '140'" />
          <el-table-column prop="rec_amount" :label="$t('order_manager.amount', ['RMB'])" width="120" />
          <el-table-column prop="status" :label="$t('order_manager.status')" width="150" />
          <el-table-column prop="option" :label="$t('order_manager.action')" width="240">
            <template #default="scope">
              <template v-if="scope.row.option === 'non-payment'">
                <div>
                  <span class="invite-btn" @click="payOrder(scope.row)">{{ $t('order_manager.pay_order') }}</span>
                  <!-- <span class="surplus-time">{{scope.row}}</span> -->
                  <span class="surplus-time">{{getTime(scope.row.showTime)}}</span>
                </div>
                <div>
                  <span class="invite-btn" @click="cancelOrder(scope.row)">{{ $t('order_manager.cancel_order') }}</span>
                </div>
              </template>
              <template v-else>
                <span>-</span>
              </template>
            </template>
          </el-table-column>
          <template #empty>
            <div class="empty">
              <sandNoData :text="$t('tip.noData')" />
            </div>
          </template>
        </el-table>
      </div>
      <div class="pagination-wrap">
        <Pagination
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :totalSize="totalSize"
          :current_page="currentPage"
        />
      </div>
    </div>
    <el-dialog
      v-model="cancelDialogVisible"
      :title="$t('label.attention')"
      width="470px"
    >
      <div class="delete-team__content">
        <svg-icon name="info"></svg-icon>
        <span style="line-height: 21px;">{{ $t('order_manager.cancel_order') }} {{activeData.order_id}} ？</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleCancel"
            >{{ $t('action.confirm') }}</el-button
          >
          <el-button @click="cancelDialogVisible = false">{{ $t('action.cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <order-dialog v-if="orderDialogShow" @closeDialog="closeDialog" :startTime="orderStartTime"></order-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, onBeforeUnmount, getCurrentInstance } from 'vue'
import SandNoData from '@/components/report/sandNoData'
import Pagination from '@/components/common/Pagination'
import orderDialog from '@/components/order/dialog1.vue'
import { isZh } from 'app/i18n'
import { getOrderList, getPaymentOrderCancel, getPaymentPay } from '@/services/order.js'
import { message } from '@/hooks/useUI'
import dayjs from 'dayjs'

const { appContext: { config: { globalProperties } } } = getCurrentInstance()

const productNameMap = {
  professional: globalProperties.$t('order_manager.product_map.professional'),
  enterprise: globalProperties.$t('order_manager.product_map.enterprise'),
  score: globalProperties.$t('order_manager.product_map.score')
}

const orderTypeMap = {
  purchase: globalProperties.$t('order_manager.type_map.purchase'),
  renew: globalProperties.$t('order_manager.type_map.renew'),
  upgrade: globalProperties.$t('order_manager.type_map.upgrade'),
  refund: globalProperties.$t('order_manager.type_map.refund')
}
const statusMap = {
  'non-payment': globalProperties.$t('order_manager.status_map.non_payment'),
  'pay-timeout': globalProperties.$t('order_manager.status_map.pay_timeout'),
  paid: globalProperties.$t('order_manager.status_map.paid'),
  'payment-failed': globalProperties.$t('order_manager.status_map.payment_failed'),
  cancelled: globalProperties.$t('order_manager.status_map.cancelled'),
  cancel: globalProperties.$t('order_manager.status_map.cancel'),
  unsubscribe: globalProperties.$t('order_manager.status_map.unsubscribe'),
  'unsubscribed-successfully': globalProperties.$t('order_manager.status_map.unsubscribed_successfully')
}

const currentPage = ref(1)
const totalSize = ref(0)
const pageSize = ref(50)
const tableData = ref([])
const isLoading = ref(false)
const activeData = ref({})
const cancelDialogVisible = ref(false)
const orderDialogShow = ref(false)
const orderStartTime = ref('')
const intervalList = ref([])

onMounted(() => {
  getData()
})

onBeforeUnmount(() => {
  if (intervalList.value && intervalList.value.length) {
    intervalList.value.forEach(item => clearInterval(item))
  }
})

const handleSizeChange = (size) => {
  pageSize.value = size
  handleCurrentChange(1)
}
const handleCurrentChange = (page) => {
  currentPage.value = page
  getData()
}

const cancelOrder = (row) => {
  activeData.value = row
  cancelDialogVisible.value = true
}

const getOrderType = (type) => {
  for (const key in orderTypeMap) {
    const item = orderTypeMap[key]
    if (item === type) {
      return key
    }
  }
}

const handleCancel = () => {
  getPaymentOrderCancel({
    order_id: activeData.value.order_id
    // operation_type: 'cancel',
    // order_type: getOrderType(activeData.value.order_type)
  })
    .then(res => {
      message(globalProperties.$t('action.handle'))
      cancelDialogVisible.value = false
      getData()
    })
    .catch(() => {
      message(globalProperties.$t('action.net_err'), 'error')
    })
}

const payOrder = (row) => {
  const query = {
    order_id: row.order_id,
    order_type: getOrderType(row.order_type)
  }
  orderStartTime.value = row.create_time
  getPaymentPay(query).then(res => {
    if (!res.status) {
      orderDialogShow.value = true
      nextTick(() => {
        window.open(`${res.url}`)
      })
    } else {
      message(res.msg || globalProperties.$t('action.net_err'), 'error')
    }
  }).catch(() => {
    message(globalProperties.$t('action.net_err'), 'error')
  })
}

const closeDialog = (flag) => {
  orderDialogShow.value = false
  if (flag) {
    getData()
  }
}
const getData = () => {
  isLoading.value = true
  getOrderList({
    page: currentPage.value,
    size: pageSize.value
  }).then(res => {
    if (intervalList.value && intervalList.value.length) {
      intervalList.value.forEach(item => clearInterval(item))
    }
    isLoading.value = false
    totalSize.value = res.total
    tableData.value = res.order_list.map(item => {
      let productName = ''
      if (item.product_name === 'score') {
        productName = globalProperties.$t('subscribe.product_score', [(item.rec_amount * 10).toFixed(0)])
      } else {
        productName = `${productNameMap[item.product_name] || item.product_name}${item.duration ? globalProperties.$t('subscribe.product_duration', [item.duration]) : ''}`
      }
      return {
        order_id: item.order_id,
        product_name: productName,
        order_type: orderTypeMap[item.order_type] || item.order_type,
        create_time: dayjs(item.create_time).format('YYYY-MM-DD HH:mm:ss'),
        status: statusMap[item.status] || item.status,
        rec_amount: `${isZh() ? '￥' : '¥'}${item.rec_amount}`,
        option: item.status
      }
    })
    nextTick(() => {
      setTimeInterval()
    })
  })
}

const setTimeInterval = () => {
  // 设置倒计时
  tableData.value.forEach(item => {
    console.log(2222)
    console.log(item.status)
    if (item.status === '待支付' || item.status === 'To be paid') {
      const date = item.create_time
      intervalList.value.push(setInterval(() => {
        const differ = new Date().getTime() - new Date(date.replace(/-/g, '/')).getTime()
        if (differ > 30 * 60 * 1000) {
          // dateItem.show = false
          item.showTime = 0
          item.option = 'pay-timeout'
          item.status = statusMap['pay-timeout']
        } else {
          item.showTime = 30 * 60 * 1000 - differ
        }
      }, 1000))
      const differ = new Date().getTime() - new Date(date.replace(/-/g, '/')).getTime()
      if (differ > 30 * 60 * 1000) {
        // dateItem.show = false
        item.showTime = 0
      } else {
        item.showTime = 30 * 60 * 1000 - differ
      }
    }
  })
}

const getTime = (date) => {
  console.log(22222)
  console.log(date)
  if (!date) {
    return ''
  }
  date = date / 1000
  const h = Math.floor(date / 3600)
  const m = Math.floor((date - h * 3600) / 60)
  const s = Math.floor(date - h * 3600 - m * 60)
  return globalProperties.$t('subscribe.left_time', [h, m, s])
}

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.order-wrapper {
  padding: 0 24px;
  padding-bottom: 24px;
  min-height: calc(100% - 85px);
  .order-title {
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-1);
  }
  .content-wrapper {
    background-color: var(--detect-config-bg);
    border-radius: 2px;
    min-height: calc(100vh - 240px);
    padding: 24px 0;
    .list-wrap {
      padding: 0 24px;
      .invite-btn {
        font-size: 14px;
        cursor: pointer;
        padding: 2px 0px;
        color:  var(--color-priamry);
        img {
          vertical-align: middle;
          margin-right: 4px;
        }
        // &:not(:last-child) {
        //   margin-right: 20px;
        // }
        // &:hover {
        //   background: #445186;
        //   border-radius: 3px;
        // }
      }
    }
    .pagination-wrap {
      padding: 10px 3px 24px;
    }
    :deep(.el-table__row) {
      height: 48px;
    }
    :deep(.el-table__header) {
      height: 48px;
      .el-table__cell {
        background-color: var(--color-bg-3);
      }
    }
    .empty {
      height: calc(100vh - 322px);
    }
  }
}
</style>

<style lang="scss">
.delete-team__content {
  display: flex;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
  }
}
</style>
